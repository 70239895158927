import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { Box } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import { TabletAndDesktop, PhoneOnly } from '~/components/Buckets';
import {
  PageWrap,
  Background,
  MobileBackground,
  IntroContent,
  CallToAction,
  StyledUl,
  StyledLi
} from './elements';
import ExternalLink from '~/components/Buttons/ExternalLink';
import { LogoSVG } from '~/components/HeroSection/elements/SVGImage';
import Logo from '~/components/Logo';
import IconScroll from '~/components/IconScroll';
import buttonColors from '~/constants/buttonColors';

const HeroSection = () => {
  const { t } = useTranslation('demoherointro');

  const { image: LogoSharp, alt } = t('logo', {
    returnObjects: true
  });

  const platforms = t('demoPlatforms', { returnObjects: true });
  const poster = t('backgroundImage', { returnObjects: true });

  const [scrollAlpha, setScrollAlpha] = useState(1);
  let interval;

  const scollListener = () => setScrollAlpha(1 - Math.min(1, (window.scrollY / window.innerHeight) * 5));

  useEffect(() => {
    window.addEventListener('scroll', scollListener);

    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', scollListener);
    };
  }, []);

  return (
    <Box width={1}>
      <PageWrap
        width={1}
        height={['100%', '80vh', 'calc(100vh - 100px)']}
        mt={['50px', null, '60px']}
        mb={['xsmall', 0]}
      >
        <PhoneOnly>
          <MobileBackground>
            <Img fluid={poster.image} src={poster.image.src} />
          </MobileBackground>
        </PhoneOnly>
        <TabletAndDesktop width={1}>
          <Background
            src={poster.image.src}
            height={[null, '440px', '100vh']}
            minWidth="100%"
          >
            <IconScroll
              opacity={scrollAlpha}
              bottom={['50%', null, '0px']}
              position="absolute"
            />
          </Background>
        </TabletAndDesktop>
        <IntroContent
          width="100%"
          position={[null, 'absolute']}
          mt={['50px', 'unset', '60px']}
        >
          <Box
            width={['90vw', 580, 740, '54vw']}
            mx="auto"
            mb={['unset', '150px', 'large']}
          >
            <Logo imageData={LogoSharp} alt={alt} />
          </Box>
          <CallToAction
            as="h1"
            fontSize={['32px', null, '46px']}
            mt={['22px', 'large', '0px']}
            mb={['small', 'medium', 'large']}
            px={['xlarge']}
          >
            {t('callToAction')}
          </CallToAction>
          <StyledUl width={1} data-type="logos">
            {platforms.map(
              ({
                title,
                fill,
                image,
                link,
                orientation,
                displayWidth,
                displayHeight,
                id
              }) => {
                const data = buttonColors[title]
                  ? buttonColors[title]
                  : buttonColors.default;
                const { hoverFill } = data;

                return (
                  <StyledLi key={id} maxHeight="70px">
                    <ExternalLink
                      href={link}
                      minWidth={['160px', '195px']}
                      minHeight={['50px', '60px']}
                      hoverFill={hoverFill}
                      bgColor={hoverFill}
                      m="small"
                    >
                      <LogoSVG
                        src={image.src}
                        alt={title}
                        fill={fill}
                        orientation={orientation}
                        displayWidth={displayWidth}
                        displayHeight={displayHeight}
                      />
                    </ExternalLink>
                  </StyledLi>
                );
              }
            )}
          </StyledUl>

          <CallToAction
            as="h1"
            fontSize="24px"
            mt={['medium', 'null', 'xlarge']}
            mb={['small', 'large']}
            px={['small', 'null', '350px']}
            lineHeight="26px"
          >
            {t('content')}
          </CallToAction>
        </IntroContent>
      </PageWrap>
    </Box>
  );
};

export default HeroSection;
