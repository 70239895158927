import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';

import SEO from '~/components/SEO/withData';
import HeroSection from '~/components/HeroSection';
import { Flex } from '@square-enix-private/vaquita-ui';
import DemoFeatures from '~/components/DemoFeatures';
import DemoClasses from '~/components/DemoClasses';

const DemoPage = ({ data: { demoClassesList, demoClassesSection } }) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    style={{ minHeight: '100vh' }}
  >
    <GTMData pageId="buy" breadcrumb="buy" />
    <SEO translationID="demometadata" />
    <HeroSection />
    <DemoFeatures />
    <DemoClasses classesList={demoClassesList} {...demoClassesSection} />
  </Flex>
);

DemoPage.propTypes = {
  data: PropTypes.object
};

DemoPage.defaultProps = {
  data: {}
};

export const query = graphql`
  query($locale: String!) {
    ...FooterData
    ...GenericData
    ...DemoMetadataData
    ...FlagsData
    ...MenuData
    ...AssetsData
    demoherointro(filter: { localeDirectory: { eq: $locale } }) {
      callToAction
      content
      slug
      logo {
        title
        alt
        image {
          ...FastlyProps
        }
      }
      backgroundImage {
        alt
        image {
          ...FastlyProps
        }
      }
      demoPlatforms {
        id
        link
        fill
        displayWidth
        displayHeight
        orientation
        title
        image {
          src
        }
      }
    }
    demofeatures(filter: { localeDirectory: { eq: $locale } }) {
      backgroundImage {
        alt
        image(maxHeight: 1080) {
          ...FastlyProps
        }
      }
      description
      note
      title
    }
    demofeaturedlist(filter: { localeDirectory: { eq: $locale } }) {
      order
      feature
    }
    demoregister(filter: { localeDirectory: { eq: $locale } }) {
      backgroundImage {
        image {
          ...FastlyProps
        }
      }
      mobileBackgroundImage {
        image {
          ...FastlyProps
        }
      }
      characterImage {
        alt
        image {
          ...FastlyProps
        }
      }
      register
      registerDescription
      link
    }

    demoClassesSection: democlassessection(
      filter: { localeDirectory: { eq: $locale }, dlc: { eq: "base" } }
    ) {
      description
      slug
      title
      skillsDescription
      watchVideo
      dlc
    }

    demoClassesList: classeslist(
      filter: { localeDirectory: { eq: $locale }, dlc: { eq: "base" } }
    ) {
      slug
      order
      link
      description
      title
      borderColor
      dlc
      image {
        alt
        image(maxHeight: 1080) {
          ...FastlyProps
        }
      }
      thumbLink {
        alt
        image {
          ...FastlyProps
        }
      }
      skills {
        title
        alt
        image {
          ...FastlyProps
        }
      }
      icon {
        alt
        image(maxHeight: 120) {
          ...FastlyProps
        }
      }
    }
  }
`;

export default DemoPage;
