import { Flex, Box } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import {
  right,
  fontSize,
  width,
  padding,
  space,
  textAlign
} from 'styled-system';

export const PageWrap = styled(Flex)`
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 320px;
  position: relative;
  overflow: hidden;
`;

export const Background = styled.img`
  height: 100%;
  position: absolute;
  ${right};
  top: 0;
`;

export const MobileBackground = styled(Box)`
  min-width: 100%;
  height: 100%;
`;

export const ContentWrap = styled(Flex)`
  position: relative;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

export const ContentSpace = styled(Box)`
  ${padding};
  width: 100%;
`;

export const StyledUl = styled.ul`
  background: rgba(0, 0, 0, 0.6);
  padding: 0 20px;
  margin: 0px;
  ${width};
  overflow: hidden;
  box-sizing: border-box;
`;

export const StyledLi = styled.li`
  list-style: none;
  padding: 0;
  line-height: 150%;
  display: flex;
  align-items: center;
  margin: 6px 0;
  min-height: 66px;
  color: ${({ theme: { colors } }) => colors.text.tertiary};
  font-family: korolev-condensed, sans-serif;
  ${fontSize};
  ${textAlign};
`;

export const Feature = styled.li`
  position: relative;
  list-style: none;
  padding: 0;
  line-height: 150%;
  ${fontSize}
  padding: 6px 0;
  padding-left: 30px;
  display: flex;
  align-items: center;
  min-height: 66px;

  &::before {
    content: '';
    width: 13px;
    height: 13px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #d2d2d2;
  }
`;

export const FeatureBackground = styled.div`
  position: absolute;
  top: 0;
  left: -20px;
  right: -20px;
  height: 100%;
  pointer-events: none;

  &::before {
    content: '';
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(126, 126, 126, 1) 0%,
      rgba(126, 126, 126, 0) 80%
    );
  }
  &::after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(126, 126, 126, 1) 0%,
      rgba(126, 126, 126, 0) 80%
    );
  }
`;

export const NoteDisclamer = styled.p`
  width: 100%;
  ${textAlign};
  color: #b9b9b9;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
  line-height: 20px;
  ${fontSize};
  ${space};
`;
