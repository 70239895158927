import React from 'react';
import { Box, Flex } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';
import SectionTitle from '~/components/SectionTitle';
import Img from 'gatsby-image';
import {
  Background,
  MobileBackground,
  ContentSpace,
  ContentWrap,
  StyledUl,
  StyledLi,
  Feature,
  FeatureBackground,
  PageWrap,
  NoteDisclamer
} from './elements';
import { TabletAndDesktop, PhoneOnly } from '~/components/Buckets';

const DemoFeatures = () => {
  const { t } = useTranslation('demofeatures');
  const features = useTranslation('demofeaturedlist').t('content', {
    returnObjects: true
  });

  const poster = t('backgroundImage', { returnObjects: true });

  return (
    <Box width={1}>
      <PageWrap
        minHeight={[520, 480]}
        width={1}
        mt={['large', null, 'xlarge']}
        mb={['xsmall', 0]}
      >
        <TabletAndDesktop>
          <Flex justifyContent="center">
            <Background
              src={poster.image.src}
              alt={poster.alt}
              right={['auto', null, '0']}
              height="100%"
            />
          </Flex>
        </TabletAndDesktop>
        <ContentWrap>
          <ContentSpace px={[20, 40]}>
            <Flex
              width={1}
              justifyContent={['center', 'left']}
              mb={[0, 'small']}
            >
              <SectionTitle
                mx={['auto', 0]}
                mb={0}
                title={t('title')}
                justifyContent={['center', 'left']}
              />
            </Flex>

            <Box width={['auto', null, '50%']} mr={['unset', '100px', 'unset']}>
              <StyledUl>
                <StyledLi fontSize="24px" textAlign={['center', 'left']}>
                  {t('description')}
                </StyledLi>
                <PhoneOnly>
                  <MobileBackground>
                    <Img
                      fluid={poster.image}
                      src={poster.image.src}
                      alt={poster.alt}
                    />
                  </MobileBackground>
                </PhoneOnly>
                {features.map(({ order, feature }) => (
                  <Feature key={order + feature} fontSize="large">
                    <FeatureBackground />
                    {feature}
                  </Feature>
                ))}
              </StyledUl>
              <NoteDisclamer
                fontSize="medium"
                mt="small"
                textAlign={['center', 'left']}
              >
                {t('note')}
              </NoteDisclamer>
            </Box>
          </ContentSpace>
        </ContentWrap>
      </PageWrap>
    </Box>
  );
};

DemoFeatures.propTypes = {};

export default DemoFeatures;
