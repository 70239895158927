import { Heading, Flex, Box } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import {
  position,
  fontSize,
  space,
  lineHeight,
  maxHeight
} from 'styled-system';

export const PageWrap = styled(Flex)`
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 320px;
  position: relative;
`;

export const Background = styled(Box)`
  width: 100%;
  max-height: calc(100vh - 100px);
  position: relative;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  overflow: hidden;

  ::after {
    content: '';
    width: 100%;
    height: 75%;
    position: absolute;
    bottom: 0px;
    background: linear-gradient(
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.9) 90%
    );
  }
`;

export const Gradient = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.7) 75%,
    rgba(0, 0, 0, 1) 99%
  );
`;

export const MobileBackground = styled(Box)`
  min-width: 100vw;
  height: 100%;
`;

export const IntroContent = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${position}
  bottom: 0px;
  ${space};
`;

export const CallToAction = styled(Heading)`
  width: 100%;
  text-align: center;
  ${fontSize};
  ${lineHeight};
  ${space};
`;

export const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  z-index: 1;
`;

export const StyledLi = styled.li`
  display: flex;
  justify-content: center;
  ${maxHeight};
  ${space};
`;
